import React from "react";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      <div className="rs-breadcrumbs img3">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Products</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li>Products</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-services services-main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-35">
            <h2 className="title">Our Products</h2>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 sm-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/Trapezoidal.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon6.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Trapezoidal Roofing Sheets</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Trapezoidal roofing sheets are a type of roofing
                        material that is commonly used in both residential and
                        commercial construction.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/trapezoidal">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Trapezoidal Roofing Sheets</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon6.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 sm-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/Purlins.png"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon6.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Purlins</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Purlins are horizontal structural members in a roof
                        framework or system that provide support for the roof
                        covering and transfer loads to the main structural
                        elements of the building, such as beams or rafters.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/Purlins">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Purlins</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon6.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/MetalBuildingAccessories.png"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon4.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="virtual-">Metal Building Accessories</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Metal building accessories are components and add-ons
                        designed to enhance the functionality, aesthetics, and
                        overall performance of metal buildings. These
                        accessories play a crucial role in improving the
                        durability, efficiency, and appearance of metal
                        structures.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/metalbuilding">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="virtual-">Metal Building Accessories</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon4.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mb-30 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/MasterRoofing.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon1.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Roofing Crimp Curve, Master</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        A gentle method of smoothly bending profiled sheets to
                        handle curves that were previously impossible is called
                        the Master Roofing Crimp Curve.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/crimpCurve">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Roofing Crimp Curve, Master</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon1.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-30 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/RoofingProfile.png"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon2.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="property-maintenance.html">
                            Roofing Master Profile Ridge
                          </Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        As per the requirements and drawings provided by the
                        client, Master Roofing can produce and supply plain
                        ridges, profile ridges, valley gutters, gutters, etc. as
                        well as down pipes.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/profileRidge">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="property-maintenance.html">
                          Roofing Master Profile Ridge
                        </Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon2.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/Air ventilators.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon6.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">ventilators by Master Roofing Air</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        We produce Master Roofing, an energy-efficient air
                        ventilator. Utilises just wind energy; no power,
                        generator, or battery backup is required. Quiet and
                        resistant to water leaks.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/roofingAir">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">ventilators by Master Roofing Air</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon6.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 sm-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/SelfDrillingScrews.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon5.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="preconstruction.html">
                            Master Self Drilling Screws for Roofing
                          </Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        A variety of top-notch, exceptional fasteners are
                        available from Master Roofing Supplies. Steel 1022 and
                        stainless steel 303, 304, 305, and 316 are both
                        materials available for self-drilling screws.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/drillingScrews">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">
                          Master Self Drilling Screws for Roofing
                        </Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon5.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/industrial-hed.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon3.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Pre-Engineered Buildings </Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Pre-engineered buildings (PEBs) are a type of
                        construction method where the structural components of a
                        building are manufactured in a factory and then
                        assembled on-site.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/preengineered">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Pre-Engineered Buildings </Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon3.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-md-6 mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/Eve Gutter.webp"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon3.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Roofing Master Eve Gutter</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Master Roofing can produce and supply down pipes as well
                        as plain ridges, profile ridges, valley gutters, and
                        other gutters based on the specifications and drawings
                        of the client.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/eveGutter">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Roofing Master Eve Gutter</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon3.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/PlainRidge.webp"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon4.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="virtual-">Roofing Master Plain Ridge</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        In accordance with the specifications and designs of the
                        client, Master Roofing can manufacture and provide down
                        pipes as well as plain and profile ridges, valley
                        gutters, and other gutters.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/plainRidge">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="virtual-">Roofing Master Plain Ridge</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon4.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 sm-mb-30 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/SheetsPurlins.WEBP"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon6.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Sheets Purlins</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Purlins are beams that can be used to create a building
                        framework to support the weight of roofing sheets or
                        wall cladding sheets attached.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/SheetsPurlins">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Sheets Purlins</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon6.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 md-mb-30 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/soofingsheetsseparately1.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon4.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="virtual-">Roofing Sheets Separately</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Roofing sheets are materials commonly used for covering
                        roofs of buildings to protect them from weather elements
                        and provide insulation. They come in various materials,
                        designs, and sizes to suit different architectural
                        styles and functional requirements.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/roofingSheetsSeparately">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="virtual-">Roofing Sheets Separately</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon4.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mb-30 mt-lg-5">
              <div className="services-item">
                <div className="services-wrap">
                  <div className="services-image">
                    <img
                      src="assets/images/services/Products/purlinsseparately.jpg"
                      alt="Services"
                    />
                  </div>
                  <div className="services-content">
                    <div className="service-inner">
                      <div className="icon-top">
                        <img
                          src="assets/images/services/main-home/main-icons/service-icon3.png"
                          alt="Services"
                        />
                      </div>
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to="">Purlins Separately</Link>
                        </h3>
                      </div>
                      <p className="services-txt">
                        Purlins are structural elements used in the construction
                        of roofs and walls in buildings. They play a crucial
                        role in supporting the roof covering and transferring
                        the load from the roof to the main structural framework
                        of the building.
                      </p>
                      <div class="services-btn">
                        <Link class="btn-text" to="/purlinsSeparately">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="normarl-text-area">
                    <div className="services-titles">
                      <h3 className="title">
                        <Link to="">Purlins Separately</Link>
                      </h3>
                    </div>
                    <div className="icon-image">
                      <img
                        src="assets/images/services/main-home/main-icons/service-icon3.png"
                        alt="Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
