import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import MainLayOut from './components/MainLayOut';
import { useEffect } from 'react';
import Home from './pages/Home';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import Infrastructure from './pages/Infrastructure';
import Products from './pages/products/Products';
import CrimpCurve from './pages/products/CrimpCurve';
import ProfileRidge from './pages/products/ProfileRidge';
import RoofingAir from './pages/products/RoofingAir';
import EveGutter from './pages/products/EveGutter';
import DrillingScrews from './pages/products/DrillingScrews';
import PlainRidge from './pages/products/PlainRidge';
import SheetsPurlins from './pages/products/SheetsPurlins';
import RoofingSheetsSeparately from './pages/products/RoofingSheetsSeparately';
import PurlinsSeparately from './pages/products/PurlinsSeparately';
import Trapezoidal from './pages/products/Trapezoidal';
import Purlins from './pages/products/Purlins';
import MetalBuilding from './pages/products/MetalBuilding';
import PreEngineered from './pages/products/PreEngineered';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/infrastructure" element={<Infrastructure />} />
            <Route path="/products" element={<Products />} />
            <Route path="/crimpCurve" element={<CrimpCurve />} />
            <Route path="/profileRidge" element={<ProfileRidge />} />
            <Route path="/roofingAir" element={<RoofingAir />} />
            <Route path="/eveGutter" element={<EveGutter />} />
            <Route path="/drillingScrews" element={<DrillingScrews />} />
            <Route path="/plainRidge" element={<PlainRidge />} />
            <Route path="/SheetsPurlins" element={<SheetsPurlins />} />
            <Route path="/purlinsSeparately" element={<PurlinsSeparately />} />
            <Route path="/trapezoidal" element={<Trapezoidal />} />
            <Route path="/Purlins" element={<Purlins />} />
            <Route path="/metalbuilding" element={<MetalBuilding />} />
            <Route path="/preengineered" element={<PreEngineered />} />
            <Route
              path="/roofingSheetsSeparately"
              element={<RoofingSheetsSeparately />}
            />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
