import React from "react";
import { Link } from "react-router-dom";

const DrillingScrews = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">
              Master Self Drilling Screws for Roofing
            </h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li>Master Self Drilling Screws for Roofing</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">
                  Master Self Drilling Screws for Roofing
                </h2>
                <p className="desc pb-37">
                  People often confuse self-drilling screws for self-tapping
                  screws. Self-tapping screws/fasteners tap their own threads,
                  but cannot drill through hard objects like metals.
                  Self-tapping screws require a pilot hole for proper
                  installation, but self-drilling screws, the other hand, don’t
                  require any pilot hole. In terms of likeness, self-tapping
                  screws and self-drilling screws can tap their own threads, but
                  generally, self-drilling screws come with a higher thread
                  count compared to self-tapping screws.
                </p>
                <p className="desc pb-37">
                  In a nutshell, all self-drilling screws are a form of
                  self-tapping screws, but not all self-tapping screws are
                  self-drilling screws, and due to the above differences these
                  screws cannot be used interchangeably, and mixing them may
                  cause some headache at the very least.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/RoofingScrew.webp"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrillingScrews;
