import React from "react";

const WorkingProcess = () => {
  return (
    <>
      <div className="rs-process process-style3 bg23 pt-150 pb-115 md-pt-115 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="sec-title mb-70 md-mb-15">
                <h2 className="title title4 ser-style1">
                  Our working process - how we work for our customers
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container custom3">
          <div className="process-bg">
            <div className="row">
              <div className="col-xl-3 col-md-6 md-40">
                <div className="process-item process-gap">
                  <div className="number-image">
                    <img
                      src="assets/images/process/style3/icons/p1.png"
                      alt="Images"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix">01</span>
                    </div>
                    <h3 className="number-title">Planning</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 md-40">
                <div className="process-item process-gap">
                  <div className="number-image">
                    <img
                      src="assets/images/process/style3/icons/p2.png"
                      alt="Images"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix">02</span>
                    </div>
                    <h3 className="number-title">Design</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 sm-mb-40">
                <div className="process-item">
                  <div className="number-image">
                    <img
                      src="assets/images/process/style3/icons/p3.png"
                      alt="Images"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix">03</span>
                    </div>
                    <h3 className="number-title">Development</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="process-item">
                  <div className="number-image">
                    <img
                      src="assets/images/process/style3/icons/p4.png"
                      alt="Images"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix">04</span>
                    </div>
                    <h3 className="number-title">Deliver</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingProcess;
