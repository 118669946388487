import React from "react";
import { Link } from "react-router-dom";
import HappyClients from "../home/HappyClients";
import HomeContact from "../home/HomeContact";

const About = () => {
  return (
    <>
      <div>
        <div className="rs-breadcrumbs img1">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">About Us</h1>
              <ul className="breadcrumbs-area">
                <li title="Go to konstruk">
                  <Link className="active" to="/">
                    Home
                  </Link>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="sec-title">
                  <span className="sub-text">
                    <img src="assets/images/about/home1.png" alt="favicon" />
                    Who We Are
                  </span>
                  <h2 className="title pb-20">
                    Construction solutions focused future ready!
                  </h2>
                  <p className="desc pb-37">
                    Sai Ram Buildtech Systems (SRBS) is incorporated on 1st July
                    2017, as one of the most complete providers of a wide range
                    of services including design, engineering, construction,
                    fabrication, maintenance and environmental services, no
                    project is too big for us. Our timely and cost-effective
                    solutions not only satisfy our customers' needs, but also
                    improve the quality of life for people around the world.
                  </p>
                  <p className="desc pb-37">
                    Pre-Engineering Buildings (PEB) products and services are
                    mostly manufactured in house and we specialize in this area.
                    Our State of the Art fabrication facility is capable of
                    delivering more than 800 Metric Tonnes of PEB Products per
                    month.
                  </p>
                  <p className="desc pb-37">
                    We are a global provider of professional, technical and
                    management support for infrastructure services to a broad
                    range of markets, including transportation, facilities,
                    environmental, energy, water and government. We provide a
                    blend of global reach, local knowledge, innovation and
                    technical excellence in delivering solutions that create,
                    enhance and sustain the world's built, natural and social
                    environments.
                  </p>

                  <div className="row mt-47 md-mt-25">
                    <div className="col-lg-6 col-md-4 sm-mb-30">
                      <div className="btn-part">
                        <Link className="readon more know" to="/contact">
                          Contact
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-8">
                      <div className="signeture-img">
                        <img
                          src="assets/images/about/style1/signeture1.png"
                          alt="Images"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                  <img
                    src="assets/images/about/style1/Roofing.png"
                    alt="About"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                  <img src="assets/images/about/style1/about.jpg" alt="About" />
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="sec-title">
                  <p className="desc pb-37">
                    Projects are more powerful when driven by a purpose. The
                    purpose that brings our experts together with our clients is
                    to create, enhance and sustain the world's built natural and
                    social environments.
                  </p>

                  <p className="desc pb-37">
                    The scope and scale of this aspiration unites scientists,
                    planners, architects, engineers, program, cost and
                    construction managers. It partners us with public sector
                    clients at every level and private sector clients in every
                    industry. We work at every scale, from an intimate garden,
                    to a city block, to a national infrastructure program.
                  </p>
                  <p className="desc pb-37">
                    Across our spectrum of expertise, we make the connections
                    for each client that best serve their immediate objective
                    while fulfilling our shared purpose.
                  </p>

                  <div className="row mt-47 md-mt-25">
                    <div className="col-lg-6 col-md-4 sm-mb-30">
                      <div className="btn-part">
                        <Link className="readon more know" to="/contact">
                          Contact
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-8">
                      <div className="signeture-img">
                        <img
                          src="assets/images/about/style1/signeture1.png"
                          alt="Images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HappyClients />
      <HomeContact />
    </>
  );
};

export default About;
