import React from "react";
import { Link } from "react-router-dom";

const MetalBuilding = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Metal Building Accessories</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Metal Building Accessories</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20"> Metal Building Accessories</h2>
                <p className="desc pb-37">
                  Sai Ram Buildtech Systems, a preeminent supplier of roofing
                  materials and accessories, manufactures, supplies, and
                  distributes a great selection of goods both domestically and
                  internationally. Gutter and downspout pipes, metal louvres,
                  wind ventilators, polycarbonate sheets, insulation material,
                  ridge ventilators/ridge monitors, crimped curved sheets,
                  flashings and many other goods are among the broad and
                  complete variety of products we offer.
                </p>
                <p className="desc pb-37">
                  One of the most popular items we sell is metal louvres. We are
                  the top Industrial Louvres Manufacturers in India since there
                  is a huge demand for these and we offer them in a range of
                  sizes. Because Sai Ram Buildtech Systems uses materials of the
                  highest quality, its products can be used effectively in
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/metalbuilding.jpg"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetalBuilding;
