import React from "react";
import { Link } from "react-router-dom";

const SheetsPurlins = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Sheets Purlins</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Sheets Purlins</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Sheets Purlins</h2>
                <p className="desc pb-37">
                  Sheets typically refer to large, flat, and thin materials made
                  from various substances such as metal, plastic, wood, or
                  composite materials. In construction, sheets are often used to
                  cover surfaces, provide structural support, or create
                  barriers. Some common types of sheets used in construction
                  include roofing sheets, wall cladding sheets, plywood sheets,
                  and metal sheets.
                </p>
                <p className="desc pb-37">
                  Purlins are horizontal structural members in a building's roof
                  system that provide support for the roof covering and transfer
                  loads to the primary structural elements, such as beams or
                  trusses. They are typically laid parallel to the slope of the
                  roof and run perpendicular to the main roof trusses or
                  rafters. Purlins help distribute the weight of the roof and
                  any additional loads, such as snow or wind, evenly to the
                  supporting framework.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/sheetspurlins.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SheetsPurlins;
