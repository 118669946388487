import React from "react";

const VisionMission = () => {
  return (
    <>
      <div className="rs-services services-style13 gray-bg6 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div class="sec-title text-center mb-50">
            <h4 class="title title4 ser-style2">
              <span>Vision & Mission</span>
            </h4>
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-6 mb-20">
              <div className="services-item">
                <div className="serial-number"> 01</div>
                <div className="services-icon">
                  <img src="assets/images/services/mission.png" alt="Images" />
                </div>
                <div className="services-text">
                  <h4 className="title">
                    <a to="">Mission</a>
                  </h4>
                  <p className="services-txt">
                    To constantly innovate and adapt new technologies to provide
                    items that are both reasonably priced and of high quality.
                    To ensure that all stakeholders—workers, vendors, customers,
                    employees, and everyone else in society—live in harmony and
                    without stress by treating each person with respect and
                    compassion on an equal footing
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 mb-20">
              <div className="services-item">
                <div className="serial-number"> 02</div>
                <div className="services-icon">
                  <img src="assets/images/services/vission.jpg" alt="Images" />
                </div>
                <div className="services-text">
                  <h4 className="title">
                    <a to="">Vision</a>
                  </h4>
                  <p className="services-txt">
                    To be the most well-known brand in the pre-engineered
                    building (PEB) sector and to wow customers with products and
                    services to always innovate and deliver the highest-quality
                    PEB goods and services.
                  </p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisionMission;
