import React from "react";

const Values = () => {
  return (
    <>
      <div
        id="rs-services"
        className="rs-services services-style3 bg5 pt-120 pb-120 md-pt-70 md-pb-80"
      >
        <div className="container">
          <div className="sec-title2 text-center mb-65 md-mb-45">
            <h2 className="title pb-25">Values</h2>
            <div className="heading-border-line" />
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img src="assets/images/services/valuaa.png" alt="Services" />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Fair Processes</a>
                  </h2>
                  <p className="services-txt">
                    We pledge to treat everyone fairly and transparently,
                    including both public and private entities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img src="assets/images/services/Urban.png" alt="Services" />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Urban-Rural Balance</a>
                  </h2>
                  <p className="services-txt">
                    We serve both the urban and rural areas without bias or
                    discrimination and offer equal opportunity to both urban and
                    rural organisations and people.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/Inclusion.png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Inclusion</a>
                  </h2>
                  <p className="services-txt">
                    We firmly think that when each member of society has the
                    chance to develop, our society as a whole is stronger.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 md-mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/Empowerment.png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Empowerment</a>
                  </h2>
                  <p className="services-txt">
                    We support giving power to all parties involved. We
                    encourage the sharing of ideas that improve people's lives.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/mutual (3).png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Mutual Support</a>
                  </h2>
                  <p className="services-txt">
                    We help to develop connections so that everyone around us
                    can help one another.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/EnvironmentFriendly.png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Environment Friendly</a>
                  </h2>
                  <p className="services-txt">
                    100% of the processes used to create our products are
                    eco-friendly. Most parts can be recycled with little effort
                    or expense.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;
