import React from "react";

const HomeContact = () => {
  return (
    <>
      <div className="rs-contact contact-style5 pt-120 md-pt-80">
        <div className="row">
          <div className="col-xl-4 col-sm-6 sm-mb-30">
            <div className="contact-box">
              <div className="contact-icon">
                <img src="assets/images/contact/contact1.png" alt="images" />
              </div>
              <div className="content-text">
                <h4 className="title">
                  <a to="#">Office Address</a>
                </h4>
                <p className="services-txt">
                  Plot No 31 Part, TIF MSME Green Industrial Park,
                  Dandumalkapur, Choutuppal, Yadadri Bhuvanagiri Dist,
                  Telangana-508252.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 xs-mb-30">
            <div className="contact-box">
              <div className="contact-icon">
                <img src="assets/images/contact/contact3.png" alt="images" />
              </div>
              <div className="content-text">
                <h4 className="title">
                  <a to="#">Email us</a>
                </h4>
                <p className="services-txt">
                  <a href="mailto:  Sales@sairambuildtechsystems.com">
                    Sales@sairambuildtechsystems.com
                  </a>
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="contact-box">
              <div className="contact-icon">
                <img src="assets/images/contact/contact2.png" alt="images" />
              </div>
              <div className="content-text">
                <h4 className="title">
                  <a to="#">Call us</a>
                </h4>
                <p className="services-txt">
                  {/* <a href="tel: +91 9440041122">(+91) 9440041122</a>
                  <br />
                  <a href="tel: +91 7338934958">(+91) 7338934958</a>
                  <br />
                  <a href="tel: +91 8886533558">(+91) 8886533558</a> */}
                  <a href="tel: +91 9391811230">(+91) 9391811230</a>
                  <br />
                  <a href="tel:  08694-293031"> (+91) 08694-293031</a>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default HomeContact;
