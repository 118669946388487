import React from "react";

const Advantages = () => {
  return (
    <>
      <div
        id="rs-services"
        className="rs-services services-style3 bg5 pt-120 pb-120 md-pt-70 md-pb-80"
      >
        <div className="container">
          <div className="sec-title2 text-center mb-65 md-mb-45">
            <h2 className="title pb-25">Advantages</h2>
            <div className="heading-border-line" />
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img src="assets/images/services/valuaa.png" alt="Services" />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Reduced Construction Time</a>
                  </h2>
                  <p className="services-txt">
                    Typically, buildings are delivered within a few weeks of the
                    blueprints being approved. The foundation and anchor bolts
                    have been parallel-cast and are prepared for site-bolting.
                    According to our assessment, using PEB will save the
                    project's overall construction time in India by at least
                    50%. Additionally, it enables quicker occupancy and earlier
                    income realisation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img src="assets/images/services/Urban.png" alt="Services" />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Lower cost</a>
                  </h2>
                  <p className="services-txt">
                    A significant cost saving in design, manufacture, and
                    on-site erection is made possible by the systems approach.
                    Reduced shipping costs are achieved by nesting the secondary
                    parts and cladding.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/Inclusion.png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Flexibility Of Expansion</a>
                  </h2>
                  <p className="services-txt">
                    The installation of additional bays makes it simple to
                    lengthen buildings. By anticipating future growth, expansion
                    in breadth and height is also conceivable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 md-mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/Empowerment.png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Large Clearspans</a>
                  </h2>
                  <p className="services-txt">
                    Buildings with clear spans of up to 80 metres can be
                    provided.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-60">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    src="assets/images/services/mutual (3).png"
                    alt="Services"
                  />
                </div>
                <div className="services-text">
                  <h2 className="title">
                    <a to="">Quality Control</a>
                  </h2>
                  <p className="services-txt">
                    Building quality is guaranteed because everything is made
                    entirely in a factory under carefully monitored conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advantages;
