import React from "react";
import { Link } from "react-router-dom";

const PreEngineered = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Pre-Engineered Buildings</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Pre-Engineered Buildings</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Pre-Engineered Buildings</h2>
                <p className="desc pb-37">
                  In order to help India's infrastructure flourish, Sai Ram
                  Buildtech Systems has constantly produced high-quality
                  solutions. By establishing itself as one of the finest
                  solution providers in Metal Building Systems and giving the
                  best service in Pre-Engineered Buildings (PEB), Sai Ram
                  Buildtech Systems has established a benchmark in the metal
                  roofing sectors.
                </p>
                <p className="desc pb-37">
                  Pre-engineered steel buildings are structures that are
                  designed, detailed, and then constructed in a factory before
                  being brought to the construction site. All components are
                  integrated and put together on site using nuts and bolts,
                  which speeds up the construction process. One of the building
                  systems with the most rapid global growth is the
                  pre-engineered system. Sai Ram Buildtech Systems offers
                  complete solutions, including design and material supply.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/Engineered Buildings.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreEngineered;
