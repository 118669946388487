import React from "react";
import { Link } from "react-router-dom";

const PurlinsSeparately = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Purlins Separately</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Purlins Separately</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Purlins Separately</h2>
                <p className="desc pb-37">
                  Purlins provide structural support to the roof or wall
                  cladding and help distribute the weight of the covering
                  material, such as roofing sheets or wall panels, to the
                  primary structural components of the building.
                </p>
                <p className="desc pb-37">
                  Purlins can be categorized into two main types: roof purlins
                  and wall purlins. Roof purlins support the roof covering,
                  while wall purlins are used to support wall cladding. The
                  choice of purlin type depends on the building's design and
                  requirements.
                </p>
                <p className="desc pb-37">
                  Purlins are commonly made from various materials, including
                  wood, steel, and even aluminum. Steel purlins are a popular
                  choice due to their strength, durability, and versatility.
                  They are often cold-formed from galvanized steel for added
                  corrosion resistance.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/purlinsseparately.jpg"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurlinsSeparately;
