import React from "react";
import { Link } from "react-router-dom";

const CrimpCurve = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Roofing Crimp Curve, Master</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Roofing Crimp Curve, Master</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Roofing Crimp Curve, Master</h2>
                <p className="desc pb-37">
                  We are bringing forth a quality assured collection of Crimp
                  Curved Sheet. Using finest grade basic material and
                  sophisticated tools and machinery. These offered sheets are
                  demanded across the national market. Also, our customers can
                  avail these products from us at rock bottom rates in multiple
                  stipulations and customized solutions. Easy to install,
                  crimped curved sheets provide versatility and creativity that
                  bring new and refreshing design to industrial and domestic
                  buildings.
                </p>
                <p className="desc pb-37">
                  The combination curves and contours in concave and convex
                  shapes with flats and angles can be used to add an aesthetic
                  appeal to the building. Crimped sheets provide absolute design
                  freedom with significant cost savings in construction. This is
                  possible because: Crimped sheets require less supporting
                  framework for parapets and roofs. Reduction or elimination of
                  many flashing/cappings. Less cladding material required for
                  covering a given curve.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/CrimpCurve (2).png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="X">
        <ul class="SG">
          <li class="sgLi">
            <div class="box">
              <h3 className="text-center">Features</h3>
              <ul class="df">
                <li>Provides versatility and creativity.</li>

                <li>Eliminates use of many flashing/ capping.</li>
              </ul>
            </div>
          </li>
          <li class="sgLi">
            <div class="box">
              <h3>Items in crimped curve sheets</h3>
              <ul class="s1">
                <li>Crimp Curved Sheet</li>
                <li>Round Crimp Curved Sheet</li>
                <li>Eave Crimp</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CrimpCurve;
