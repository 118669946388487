import React from "react";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <div className="rs-about about-style1 pt-150 pb-150 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="about-image-wrap">
                <div className="images-part">
                  <img
                    className="main-img"
                    src="assets/images/about/roofing5.jpg"
                    alt="About"
                  />
                  {/* <img
                    className="top-img1"
                    src="assets/images/about/about2.png"
                    alt="About"
                  />
                  <img
                    className="middle-img2"
                    src="assets/images/about/about3.png"
                    alt="About"
                  />
                  <img
                    className="bottom-img3"
                    src="assets/images/about/about1.png"
                    alt="About"
                  /> */}
                </div>
                {/* <div className="rs-addon-services">
                  <div className="services-icon">
                    <img src="assets/images/about/home.png" alt="favicon" />
                  </div>
                  <div className="services-text">
                    <h2 className="title">
                      Leading Product Agency Since 1st July 2017
                    </h2>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 pl-80 md-pl-15">
              <div className="sec-title">
                <span className="sub-text sub-text3">
                  <img src="assets/images/about/home.png" alt="Images" />
                  About
                </span>
                <h2 className="title pb-20">
                  Committed to High Quality Product
                </h2>
                <p className="desc pb-32">
                  Sai Ram Buildtech Systems (SRBS) is incorporated on 1st July
                  2017, as one of the most complete 4 providers of a wide range
                  of services including design, engineering, construction,
                  fabrication, maintenance and environmental services,
                  specialised in PEB structures.
                </p>
                <p className="desc pb-32">
                  We produce Color Coated Galvanized and Color
                  Coated/Non-colored (Bare) Galvalume Roofing Sheets, which are
                  made of world class raw material complying with the
                  international standards of ASTM/JIS/IS. Because of its
                  excellent corrosion resistance, these Sheets have become the
                  most preferred material for various construction purposes,
                  particularly roofing and wall cladding.
                </p>

                {/* <div className="row">
                  <div className="col-lg-6 md-mb-30">
                    <div className="services-item">
                      <div className="services-icon">
                        <img
                          src="assets/images/about/style2/ser-icon1.png"
                          alt="About"
                        />
                      </div>
                      <div className="services-text">
                        <h2 className="title">
                          {" "}
                          Commercial <br />
                          Roofing
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-item">
                      <div className="services-icon">
                        <img
                          src="assets/images/about/style2/ser-icon2.png"
                          alt="About"
                        />
                      </div>
                      <div className="services-text">
                        <h2 className="title">
                          {" "}
                          Residential <br />
                          Roofing
                        </h2>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="btn-part mt-45">
                  
                  <Link className="readon red-btn know-radius" to="/about">
                    More
                  </Link>
                </div> */}
                <div>
                  <a href="assets/images/BroucherSairamBultech.pdf" download>
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{ background: "#0591d9" }}
                    >
                      Download Brochure
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
