import React from "react";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../pages/WhatsappIcon";

const Footer = () => {
  return (
    <>
      <WhatsappIcon />
      <footer
        id="rs-footer"
        className="rs-footer footer-main-home footer-style1"
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 pr-20 md-pr-15 md-mb-20">
                <div className="footer-logo mb-51 md-mb-30">
                  <a to="">
                    <h3 className="footer-title">About Us</h3>
                    {/* <img src="assets/images/logo/logo.png" alt /> */}
                  </a>
                </div>
                <div className="textwidget">
                  <p className="pb-20">
                    We at Sai Ram Buildtech Systems (SRBS) are experienced in
                    providing quality products since 6 years.
                  </p>
                  <p className="pb-25">
                    <strong>Opening Hours</strong> : Monday – Sunday: 9:30am to
                    6:30pm
                  </p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a
                      href="https://www.facebook.com/sairamindustriesprefab?mibextid=LQQJ4d"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@sairamindustriespebmanufac7598/videos"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a to="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>

                  <li>
                    <a to="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 md-mb-10">
                <h3 className="footer-title">Contact Info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="fi fi-rr-map-marker-home" />
                    <div className="desc">
                      Plot No 31 Part, TIF MSME Green Industrial Park,
                      Dandumalkapur, Choutuppal, Yadadri Bhuvanagiri Dist,
                      Telangana-508252.
                      <br />
                    </div>
                  </li>
                  <li>
                    <i className="fi fi-rr-phone-call" />
                    <div className="desc">
                      {/* <a to="tel:(+91) 9440041122">(+91) 9440041122</a>
                      <br />
                      <a to="tel:(+91) 9391811230">(+91) 7338934958</a>
                      <br />
                      <a to="tel:(+91) 8886533558">(+91) 8886533558</a> */}
                      <a href="tel: +91 9391811230">(+91) 9391811230</a>
                      <br />
                      <a href="tel:  08694-293031"> (+91) 08694-293031</a>
                    </div>
                  </li>
                  <li>
                    <i className="fi fi-rr-envelope-plus" />
                    <div className="desc">
                      <a href="mailto:  Sales@sairambuildtechsystems.com">
                        Sales@sairambuildtechsystems.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 md-mb-10">
                <h3 className="footer-title">Quick Links</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link
                      to="/about
"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/infrastructure">Infrastructure</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="copyright text-lg-start text-center">
                  <p style={{ marginLeft: "20px" }}>
                    © 2023, Sai Ram Buildtech Systems
                    <a href="https://dexterous.in" target="blank">
                      {" "}
                      | Design by Dexterous Technology
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
