import React from "react";
import { Link } from "react-router-dom";

const ProfileRidge = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Roofing Master Profile Ridge</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Roofing Master Profile Ridge</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Roofing Master Profile Ridge</h2>
                <p className="desc pb-37">
                  Roofing is an essential part of building both residential and
                  commercial constructions. Every structure needs a sturdy,
                  reliable roof that can shield the occupants from the elements.
                  In recent years, technology has produced a wide range of
                  roofing materials. Color-coated roofing sheets have grown in
                  popularity during the past few years. They are simple to
                  install due to their flexibility and durability.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img src="assets/images/about/style1/Profile.png" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ProfileRidge;
