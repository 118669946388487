import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function HomeBanner() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 95000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[ Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="rs-slider slider-style4">
            <div className="slider slider-slide-1">
              <div className="slider-item slide-bg2">
                <div className="container custom13">
                  <div className="slider-inner">
                    <div className="content-part">
                      <span
                        className="sl-subtitle wow fadeInDown"
                        style={{ color: "#261963" }}
                      >
                        Best Quality
                      </span>
                      <h1 className="sl-title wow fadeInLeft">
                        Wel Come To Sai Ram Buildtech Systems
                      </h1>
                      <p className="sl-desc wow fadeInLeft2">
                        Manufacturers of Roofing sheets , Purlins & all roofing
                        accessories
                      </p>
                      <div className="slider-bottom wow fadeinup">
                        <Link className="readon red-btn" to="/about">
                          Discover More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rs-slider slider-style4">
            <div className="slider slider-slide-1">
              <div className="slider-item">
                <div className="container custom13">
                  <div className="slider-inner">
                    <div className="content-part">
                      <span
                        className="sl-subtitle wow fadeInDown"
                        style={{ color: "#261963" }}
                      >
                        Best Quality
                      </span>
                      <h1 className="sl-title fadeInLeft">
                        Important Roofing Accessories
                        <br />
                        You Need for Your Roof
                      </h1>
                      <div className="slider-bottom wow fadeinup">
                        <Link className="readon red-btn" to="/about">
                          Discover More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
