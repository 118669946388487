import React from "react";
import { Link } from "react-router-dom";

const EveGutter = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Roofing Master Eve Gutter</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Roofing Master Eve Gutter</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Roofing Master Eve Gutter</h2>
                <p className="desc pb-37">
                  There is only one solution to preserve the facades of your
                  house in the long term: install a gutter! As an integral part
                  of the roof, the gutter is designed to collect rainwater so
                  that it does not run off the walls or – worse – seep into your
                  home. In other words, they ensure that the water is drained
                  away. When replacing old gutters on your Melbourne home, there
                  are different types of gutters to choose from, depending on
                  precise parameters. Sai Ram Buildtech Systems (SRBS) Roofing
                  will help you to understand them in this article.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/EveGutter.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EveGutter;
