import React from "react";
import { Link } from "react-router-dom";

const Purlins = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Purlins</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Purlins</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Purlins</h2>
                <p className="desc pb-37">
                  Purlins and Girts of the highest quality are provided by Sai
                  Ram Buildtech Systems and are frequently used in both
                  commercial and industrial settings. These purlins, which come
                  in "C" and "Z" shapes and with cut-to-length lines, are ideal
                  for use in garages, carports, warehouses, and industrial
                  sheds.
                </p>
                <p className="desc pb-37">
                  Our purlins are resistant to rust and can readily survive
                  severe weather. We are among the most reputable purlin
                  manufacturers in India as a result of this. Buildtech Systems
                  by Sai Ram Purlins are specially created to provide building
                  construction with economy, to keep the site tidy and
                  environmentally friendly, to make the building robust and
                  durable to retain position for a very long time.
                </p>
                <p className="desc pb-37">
                  'Z' and 'C' sections are precisely roll formed from high
                  strength galvanised steel, cut to the length, and pre-punched.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img src="assets/images/about/style1/purlins.jpg" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purlins;
