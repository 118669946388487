import React from 'react'
import { Link } from "react-router-dom";


const RoofingAir = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Ventilators by Master Roofing Air</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Ventilators by Master Roofing Air</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">
                  Ventilators by Master Roofing Air
                </h2>
                <p className="desc pb-37">
                  The high-quality metal wind driven roof vents we manufacture
                  are corrosion resistant, eliminates smoke and fumes and ensure
                  even distribution of ventilation.
                </p>
                <p className="desc pb-37">
                  We produce Master Roofing, an energy-efficient air ventilator.
                  Utilises just wind energy; no power, generator, or battery
                  backup is required. Quiet and resistant to water leaks. Master
                  Roofing's air ventilators remove heat, dust, smoke,
                  pollutants, and humidity while sucking in fresh, healthier air
                  for people. As a consequence, businesses will operate more
                  efficiently and productively. The air ventilator from Master
                  Roofing is best suited for any structure with a hot, dusty and
                  stuffy climate.Particularly appropriate for industrial sheds,
                  factories, warehouses, medical facilities, godowns, dining
                  establishments, hotels, and paint shops.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/RoofingAir.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RoofingAir