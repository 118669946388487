import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAbout from "./home/HomeAbout";
import HappyClients from "./home/HappyClients";
import VisionMission from "./home/VisionMission";
import Values from "./home/Values";
import BenefitFrom from "./home/BenefitFrom";
import Advantages from "./home/Advantages";
import WorkingProcess from "./home/WorkingProcess";
import HomeContact from "./home/HomeContact";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <VisionMission />
      <Values />
      <HomeAbout />
      <HappyClients />
      <BenefitFrom />
      <Advantages />
      <WorkingProcess />
      <HomeContact />
    </>
  );
};

export default Home;
