import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <div className="rs-breadcrumbs img6">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Contact Us</h1>

            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="rs-contact contact-style6 pb-120 md-pb-80">
        <div className="rs-contact pt-120 md-pt-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 md-mb-30">
                <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/images/contact/style1/icons/address-icon.png"
                      alt="images"
                    />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="#">Office Address</a>
                    </h4>
                    <p className="services-txt">
                      {" "}
                      Plot No. 31 (Part), TIF MSME Green Industrial Park,
                      Dandumalkapur, Choutuppal, Telangana-508252
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 sm-mb-30">
                <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/images/contact/style1/icons/Email.png"
                      alt="images"
                    />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="#">Email us</a>
                    </h4>
                    <span>
                      <a href="mailto:  Sales@sairambuildtechsystems.com">
                        Sales@sairambuildtechsystems.com
                      </a>
                    </span>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/images/contact/style1/icons/Call.png"
                      alt="images"
                    />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="#">Call us</a>
                    </h4>
                    <span>
                      {/* <a href="tel: +919440041122">(+91) 9440041122</a> */}
                      <a href="tel: +91 9391811230">(+91) 9391811230</a>
                    </span>
                    <br />
                    <span>
                      <a href="tel: 08694-293031">(+91) 08694-293031</a>
                    </span>
                    {/* <br />
                    <span>
                      <a href="tel: +918886533558">(+91) 8886533558</a>
                    </span> */}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-contact contact-style1 bg3">
        <div className="row">
          <div className="col-lg-4 offset-2 con-left-6">
            <div className="contact-section">
              <div className="contact-wrap">
                <div className="sec-title mb-45">
                  <h2
                    className="title"
                    style={{ textAlign: "center", fontSize: "42px" }}
                  >
                    Contact Us
                  </h2>
                </div>
                <div id="form-messages" />
                {/* <form id="contact-form" method="post" action="mailer.php">
                  <fieldset>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                        <input
                          className="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                        <input
                          className="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                        <input
                          className="from-control"
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                        <input
                          className="from-control"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required
                        />
                      </div>
                      <div className="col-lg-12 mb-30">
                        <textarea
                          className="from-control"
                          id="message"
                          name="message"
                          placeholder="Your Message Here"
                          required
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="btn-part">
                      <div className="form-group mb-0">
                        <input
                          className="readon more submit"
                          type="submit"
                          defaultValue="Submit Now"
                        />
                      </div>
                    </div>
                  </fieldset>
                </form> */}

                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      className="gmap_iframe"
                      width="100%"
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      src="https://maps.google.com/maps?width=600&height=400&hl=en&q=sairambuildtechsystems Mandollagudem, Telangana 508252&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    />
                    <a href="https://gachanymph.com/">Gacha Nymph Download</a>
                  </div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 pr-50 md-pr-15 con-right-6 md-mt-50">
            <div className="contact-box-item">
              <div className="contact-box mb-20">
                <div className="contact-icon">
                  <img src="assets/images/contact/contact1.png" alt="images" />
                </div>
                <div className="content-text">
                  <h4 className="title">
                    <a href="#">Office Address</a>
                  </h4>
                  <p className="services-txt">
                    Plot No. 31 (Part), TIF MSME Green Industrial Park,
                    Dandumalkapur, Choutuppal, Telangana-508252
                  </p>
                </div>
              </div>
              <div className="contact-box mb-20">
                <div className="contact-icon">
                  <img src="assets/images/contact/contact3.png" alt="images" />
                </div>
                <div className="content-text">
                  <h4 className="title">
                    <a href="#">Email us</a>
                  </h4>
                  <p className="services-txt">
                    <a href="mailto:  Sales@sairambuildtechsystems.com">
                      Sales@sairambuildtechsystems.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="contact-box">
                <div className="contact-icon">
                  <img src="assets/images/contact/contact2.png" alt="images" />
                </div>
                <div className="content-text">
                  <h4 className="title">
                    <a href="#">Call us</a>
                  </h4>
                  <p className="services-txt">
                    {/* <a href="tel: +91 9440041122">(+91) 9440041122</a> */}
                    <a href="tel: +91 9391811230">(+91) 9391811230</a>
                    <br />
                    <a href="tel: 08694-293031">(+91) 08694-293031</a>
                    <br />
                    {/* <a href="tel: +918886533558">(+91) 8886533558</a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 contact-right-img" />
        </div>
      </div>
    </>
  );
};

export default Contact;
