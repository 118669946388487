import React from "react";
import { Link } from "react-router-dom";

const Trapezoidal = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Trapezoidal Roofing Sheets</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Trapezoidal Roofing Sheets</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Trapezoidal Roofing Sheets</h2>
                <p className="desc pb-37">
                  Trapezoidal roofing sheets have a profile characterized by
                  alternating ridges and valleys, forming a trapezoidal shape
                  when viewed from the side. This design enhances the strength
                  and rigidity of the sheet while allowing water to flow off
                  easily, minimizing the risk of leaks.
                </p>
                <p className="desc pb-37">
                  Metal trapezoidal sheets, often made from steel or aluminum,
                  are durable, lightweight, and resistant to weathering. They
                  are commonly used in industrial, commercial, and agricultural
                  buildings.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/updateddimensions.jpg"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trapezoidal;
