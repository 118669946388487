import React from "react";
import { Link } from "react-router-dom";
import HomeContact from "./home/HomeContact";

const Infrastructure = () => {
  return (
    <>
      <div className="rs-breadcrumbs img2">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Infrastructure</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li>Infrastructure</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="rs-about about-style1 pt-150 pb-150 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="about-image-wrap">
                <div className="images-part">
                  <img
                    className="main-img"
                    src="assets/images/about/about01.png"
                    alt="About"
                  />
                  <img
                    className="top-img1"
                    src="assets/images/about/about02.png"
                    alt="About"
                  />
                  <img
                    className="middle-img2"
                    src="assets/images/about/about04.jpg"
                    alt="About"
                  />
                  <img
                    className="bottom-img3"
                    src="assets/images/about/about03.png"
                    alt="About"
                  />
                </div>
                <div className="rs-addon-services">
                  <div className="services-icon">
                    <img src="assets/images/about/home.png" alt="favicon" />
                  </div>
                  <div className="services-text">
                    <h2 className="title">
                      Leading Product Agency Since 1st July 2017
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-80 md-pl-15">
              <div className="sec-title">
                <span className="sub-text sub-text3">
                  <img src="assets/images/about/home.png" alt="Images" />
                  Spread Over
                </span>
                <h2 className="title pb-20" style={{ fontSize: "18px" }}>
                  9000 sq. m of area with three different manufacturing units
                </h2>
                <p className="desc pb-32">
                  Production (Steel) Capacity: 9600 MT per annum
                </p>

                <div className="row">
                  <div className="col-lg-6 md-mb-30">
                    <div className="services-item">
                      <div className="services-icon">
                        <img
                          src="assets/images/about/Roofing.png"
                          alt="About"
                        />
                      </div>
                      <div className="services-text">
                        <h2 className="title">
                          Commercial <br />
                          Roofing Sheets
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services-item">
                      <div className="services-icon">
                        <img
                          src="assets/images/about/Roofing.png"
                          alt="About"
                        />
                      </div>
                      <div className="services-text">
                        <h2 className="title">
                          {" "}
                          Residential <br />
                          Roofing Sheets
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="btn-part mt-45">
                  <Link className="readon red-btn know-radius" to="/about">
                    More
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services services-style4 bg10 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-60 md-mb-40">
            <h2
              className="title white-color"
              style={{ fontSize: "42px", marginBottom: "63px" }}
            >
              Infrastructure
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/SheetRoll.jpg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Sheet Roll Forming Machine</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/UncoilMachines.jpeg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Uncoil Machines-2 numbers</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/SlittingMachine.jpeg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Slitting Machine</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 md-mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/SheetCrimpi.jpg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Sheet Crimping Machine</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/PurlinsMachine.jpg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">C & Z Purlins Machine </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img src="assets/images/services/welding.png" alt="Images" />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Saw Welding Machines- Three</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img src="assets/images/services/MIG.png" alt="Images" />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">MIG-CO2 Gas Type- 6 Nos</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 md-mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img src="assets/images/services/power.jpeg" alt="Images" />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Power Press Punching Machine</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/aircompressors.jpeg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Air Compressors</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img src="assets/images/services/PUG.jpeg" alt="Images" />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">PUG Cutters-2</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/BrushCutting.jpeg"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Brush Cutting-6</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 md-mb-40">
              <div className="services-item">
                <div className="services-image">
                  <img
                    src="assets/images/services/SandBlasting.png"
                    alt="Images"
                  />
                </div>
                <div className="services-text">
                  <div className="services-inner">
                    <h3 className="title">
                      <a to="">Sand Blasting & Painting facilities</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeContact />
    </>
  );
};

export default Infrastructure;
