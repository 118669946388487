import React from "react";
import { Link } from "react-router-dom";

const HappyClients = () => {
  return (
    <>
      <div className="rs-counter counter-style1 bg2 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="sec-title">
                <h2 className="title white-color pb-20">
                  Wel Come To Sai Ram Buildtech Systems.
                </h2>
                <p className="desc desc2 pb-45">
                  Committed to High Quality Product
                </p>
                <div className="btn-part">
                  <Link className="readon more contact" to="/contact">
                    Contact Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-6">
                  <div className="rs-counter-list">
                    <div className="count-text">
                      <div className="count-number">
                        <span className="rs-count">6</span>
                        <span className="prefix">+</span>
                      </div>
                      <span className="title">Years Experiance</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-md-6 col-sm-6 mb-30">
                  <div className="rs-counter-list">
                    <div className="count-text">
                      <div className="count-number">
                        <span className="rs-count">302</span>
                        <span className="prefix">+</span>
                      </div>
                      <span className="title">Happy Clients</span>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-md-6 col-sm-6 xs-mb-30">
                  <div className="rs-counter-list">
                    <div className="count-text">
                      <div className="count-number">
                        <span className="rs-count">153</span>
                        <span className="prefix">+</span>
                      </div>
                      <span className="title">Qualified Engineers</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6 mb-30">
                  <div className="rs-counter-list">
                    <div className="count-text">
                      <div className="count-number">
                        <span className="rs-count">341</span>
                        <span className="prefix">+</span>
                      </div>
                      <span className="title"> Completed Projects</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HappyClients;
