import React from "react";
import { Link } from "react-router-dom";

const RoofingSheetsSeparately = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Roofing Sheets Separately</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Roofing Sheets Separately</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Roofing Sheets Separately</h2>
                <p className="desc pb-37">
                  Metal roofing sheets are often made from materials like steel,
                  aluminum, or copper. They are durable, lightweight, and
                  resistant to fire and weather conditions. Metal sheets can be
                  formed into different profiles, such as corrugated, standing
                  seam, and ribbed.
                </p>
                <p className="desc pb-37">
                  These are one of the most common roofing materials. They
                  consist of a fiberglass or organic mat coated with asphalt and
                  covered with mineral granules. Asphalt shingles are
                  cost-effective and easy to install.
                </p>
                <p className="desc pb-37">
                  Clay tiles are known for their distinctive appearance and
                  longevity. They are made by baking molded clay and are popular
                  in Mediterranean and Spanish-style architecture.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/soofingsheetsseparately.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoofingSheetsSeparately;
