import React from 'react'
import { Link } from "react-router-dom";


const PlainRidge = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title"> Roofing Master Plain Ridge</h1>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li> Roofing Master Plain Ridge</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-75">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-20">Roofing Master Plain Ridge</h2>
                <p className="desc pb-37">
                  The ridge provides a strong and secure point where two
                  opposing sides of the roof come together. It helps distribute
                  the weight and stress from the roof's weight and external
                  forces more evenly. Properly designed ridge structures
                  contribute to the stability of the roof and the entire
                  building.
                </p>
                <p className="desc pb-37">
                  Ridge vents are a common feature in modern roofing. They are
                  placed along the ridge line and allow hot air and moisture to
                  escape from the attic or upper part of the roof. This
                  ventilation helps regulate the temperature of the roof,
                  prevents moisture buildup that can lead to mold and rot, and
                  improves overall energy efficiency by reducing the need for
                  excessive air conditioning.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/about/style1/PlainRidge.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlainRidge