import React, { useState } from "react";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);

  const CloseClick = () => {
    setToggle(false);
    setToggle1(false);
  };
  return (
    <>
      <div className="full-width-header">
        <header id="rs-header" className="rs-header header-style2">
          <div className="toolbar-area topbar-style1 topber-modify1 hidden-md">
            <div className="container">
              <div className="row rs-vertical-middle">
                <div className="col-lg-7">
                  <div className="toolbar-contact">
                    <ul className="rs-contact-info">
                      <li>
                        <i className="fi fi-rr-envelope-plus" />
                        <a href="mailto:  Sales@sairambuildtechsystems.com">
                          Sales@sairambuildtechsystems.com
                        </a>
                      </li>
                      <li>
                        <i className="fi-rr-map-marker-home" />
                        Choutuppal, Telangana
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="toolbar-sl-share">
                    <ul className="clearfix">
                      <li className="opening">
                        <em>
                          <i className="fi fi-rr-time-add" /> Hours: Mon-Sun:
                          9.30 am - 6.30 pm
                        </em>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/sairamindustriesprefab?mibextid=LQQJ4d"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@sairamindustriespebmanufac7598/videos"
                          target="_blank"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-area menu-sticky">
            <div className="container">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <Link to="/">
                      <img
                        className="normal-logo"
                        src="assets/images/logo/logo.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          {/* <li className="menu-item-has-children">
                            <Link to="services.html">Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="services-style1.html">
                                  Services Style 1
                                </Link>
                              </li>
                              <li>
                                <Link to="services-style2.html">
                                  Services Style 2
                                </Link>
                              </li>
                            </ul>
                          </li> */}
                          <li>
                            <Link to="/infrastructure">Infrastructure</Link>
                          </li>
                          <li>
                            <Link to="/products">Our Products</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                          <li>
                            <a href="assets/images/BroucherSairamBultech.pdf" download>
                              <button
                                type="button"
                                className="btn btn-success"
                                style={{ background: "#0591d9" }}
                              >
                                Download Brochure
                              </button>
                            </a>
                          </li>
                          {/* <li class="btn-quote">
                            <a class="quote-button" href="#">
                              Let's Talk
                            </a>
                          </li> */}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <ul>
                      {/* <li className="rs-contact-phone">
                        <i className="fi fi-rr-phone-call" />
                        <div className="phone-number">
                          <span>Free Call</span>
                          <Link href="tel: +91 9440041122">
                            (+91) 9391811230
                          </Link>
                        </div>
                      </li> */}

                      {toggle === false ? (
                        <>
                          <li className="humburger">
                            <Link
                              id="nav-expander"
                              className="nav-expander bar"
                              to=""
                              onClick={() => setToggle(!toggle)}
                            >
                              <div className="bar">
                                <span className="dot1" />
                                <span className="dot2" />
                                <span className="dot3" />
                                <span className="dot4" />
                                <span className="dot5" />
                                <span className="dot6" />
                                <span className="dot7" />
                                <span className="dot8" />
                                <span className="dot9" />
                              </div>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <div
                            className="close-btn hash-has-sub"
                            onClick={() => setToggle(!toggle)}
                          >
                            <Link id="nav-close2" className="nav-close hash">
                              <div className="line">
                                <span className="line1" />
                                X
                                <span className="line2" />
                              </div>
                            </Link>
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {toggle === true ? (
              <>
                <nav className=" mobile-navbar-menu" id="mobile-navbar-menu">
                  <ul className="nav-menu">
                    <li className="menu-item-has-children current-menu-item has-sub hash-has-sub">
                      <Link
                        to=""
                        className="hash"
                        onClick={() => setToggle(!toggle)}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="hash-has-sub">
                      <Link
                        to="/about"
                        className="hash"
                        onClick={() => setToggle(!toggle)}
                      >
                        About
                      </Link>
                    </li>
                    <li className="menu-item-has-children has-sub hash-has-sub">
                      <Link
                        to="/infrastructure"
                        className="hash"
                        onClick={() => setToggle(!toggle)}
                      >
                        Infrastructure
                      </Link>
                    </li>
                    <li className="menu-item-has-children has-sub hash-has-sub">
                      <Link
                        to="/products"
                        className="hash"
                        onClick={() => setToggle(!toggle)}
                      >
                        Our Products
                      </Link>
                    </li>
                    <li className="hash-has-sub">
                      <Link
                        to="/contact"
                        className="hash"
                        onClick={() => setToggle(!toggle)}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </>
            ) : (
              <></>
            )}
          </div>
        </header>
      </div>
    </>
  );
};

export default Hadear;
