import React from "react";

const BenefitFrom = () => {
  return (
    <>
      <div className="rs-services services-style6 gray-bg3 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-35 md-mb-25">
            <h2 className="title pb-20">
              When you partner with Sai Ram Buildtech Systems, you'll benefit
              from
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-img">
                  <a to="">
                    <img
                      src="assets/images/services/Service1.png"
                      alt="Services"
                    />
                  </a>
                </div>
                <div className="services-content">
                  <h4 className="title">
                    <a to="">A fast, cost-effective approach</a>
                  </h4>
                  <p className="services-txt">
                    Speed is essential since every minute you spend working on
                    your project is a minute you're not selling. We also work
                    hard to offer reasonable pricing for your project and its
                    upkeep, which will lower your running costs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-img">
                  <a to="">
                    <img
                      src="assets/images/services/Service2.png"
                      alt="Services"
                    />
                  </a>
                </div>
                <div className="services-content">
                  <h4 className="title">
                    <a to="">Project flexibility</a>
                  </h4>
                  <p className="services-txt">
                    For us, there is no big or small involvement. We can serve
                    as your single point of contact if you have a portfolio of
                    projects in several different marketplaces to simplify the
                    process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-img">
                  <a to="">
                    <img
                      src="assets/images/services/Service3.jpg"
                      alt="Services"
                    />
                  </a>
                </div>
                <div className="services-content">
                  <h4 className="title">
                    <a to="">Customized solutions</a>
                  </h4>
                  <p className="services-txt">
                    We carefully listen to you so that we can grasp your vision.
                    We offer a creative solution that blends the structure with
                    the processes it houses to maximise efficiency by fusing
                    your comments with our experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-img">
                  <a to="">
                    <img
                      src="assets/images/services/Service4.jpg"
                      alt="Services"
                    />
                  </a>
                </div>
                <div className="services-content">
                  <h4 className="title">
                    <a to="">Deep technical expertise</a>
                  </h4>
                  <p className="services-txt">
                    For each industrial project, we draw on the center's
                    technological know-how to deliver a product that is
                    precisely tailored to meet your requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitFrom;
